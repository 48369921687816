var makerIcons = [
  {
    pc: {
      url: "/assets/img/map_maker_1-0.png",
      scaledSize : new google.maps.Size(34, 42),
      size: new google.maps.Size(34, 42),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(17, 18)
    },
    sp: {
      url: "/assets/img/map_maker_1-0.png",
      scaledSize : new google.maps.Size(17, 21),
      size: new google.maps.Size(17, 21),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(8, 9)
    }
  }, {
    pc: {
      url: "/assets/img/map_maker_1-1.png",
      scaledSize : new google.maps.Size(34, 42),
      size: new google.maps.Size(34, 42),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(17, 18)
    },
    sp: {
      url: "/assets/img/map_maker_1-1.png",
      scaledSize : new google.maps.Size(17, 21),
      size: new google.maps.Size(17, 21),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(8, 9)
    }
  }
];

var infoBoxOpts = {
  pc: {
    pixelOffset: new google.maps.Size(-116, -28),
    closeBoxMargin: "-4px -6px 0 0",
    closeBoxURL: "/assets/img/pc/map_window_1__close.png"
  },
  sp: {
    pixelOffset: new google.maps.Size(-48, -20),
    closeBoxMargin: "-6px -4px 0 0",
    closeBoxURL: "/assets/img/sp/map_window_1__close.png"
  }
}


/*
## GoogleMap_A
*/

var GoogleMap_A_Model = Backbone.Model.extend({
  defaults: function(){
    
  }
});


COMMON.GoogleMap_A_Collection = Backbone.Collection.extend({
  url: "/data/tenji.json",
  model: GoogleMap_A_Model,
  parse: function(data){
    console.log(data);
    return data.data;
  }
});


/*
### View
*/

COMMON.GoogleMap_A = Backbone.View.extend({
  
  initialize: function(opts){
    var self = this;
    console.group("GoogleMap_A");
    _.bindAll(this, "render", "add", "fetchError");
    this.opts = _.extend({
      
    }, opts);
    
    this.template = {
      infowindow: _.template( $("#gmap_infowindow_template").html() )
    };
    
    this.collection.fetch({
      success: this.render,
      error: this.fetchError
    });
    console.log("fetch");
    
    console.groupEnd();
  },
  
  render: function(){
    console.group("render");
    
    this.map = new google.maps.Map(this.$el.get(0), {
      center: {
        lat: 36.542681,
        lng: 140.446594
      },
      zoom: 8,
      mapTypeControl: false,
      zoomControl: false,
      scaleControl: false,
      streetViewControl: false,
      fullscreenControl: false
    });
    
    this.collection.each(this.add);
    
    console.groupEnd();
  },
  
  add: function(model){
    console.group("add");
    console.log("model", model);
    var self = this;
    
    var infowindow = new InfoBox(_.extend({
      content: this.template.infowindow(model.toJSON()),
      alignBottom: true,
      boxClass: "map_infowindow_1"
    }, infoBoxOpts[(COMMON.device.Mobile)?"sp":"pc"]));
    
    var marker = new google.maps.Marker({
      position: {
        lat: model.get("lat"),
        lng: model.get("lng")
      },
      map: this.map,
      title: "hoge",
      icon: makerIcons[model.get("maker")][(COMMON.device.Mobile)?"sp":"pc"]
    });
    
    console.log( model.get("maker"), (COMMON.device.Mobile)?"sp":"pc" );
    
    google.maps.event.addListener(marker, "click", function(event){
      infowindow.open(self.map, marker);
    });
    
    console.groupEnd();
  },
  
  fetchError: function(a,b,c){
    console.group("fetchError");
    console.log(a);
    console.log(b);
    console.log(c);
    console.groupEnd();
  }
  
});