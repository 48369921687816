COMMON.GoogleMap_B = Backbone.View.extend({
  
  initialize: function(opts){
    var self = this;
    this.opts = _.extend({
      
    }, opts);
    console.group("GoogleMap_B");
    
    console.groupEnd();
  }
  
});